<template>
  <div class="PaymentDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />
          <!-- <q-btn
            icon="table_rows"
            class="buttonsBar"
            v-if="viewTable"
            @click="viewTable = !viewTable"
          />
          <q-btn
            icon="table_chart"
            class="buttonsBar"
            v-if="!viewTable"
            @click="viewTable = !viewTable"
          /> -->
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: 80vh;">
      <div v-if="viewTable">
        <q-card class="q-ma-sm" v-if="payment">
          <q-card-section>
            <div class="row justify-start">
              <div class="col-xs-12 col-sm-6 col-md-4">
                <span class="text-bold">Pagamento: </span>
                <span>{{ payment.document }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4">
                <span class="text-bold">Empresa: </span>
                <span>{{
                  `${payment.branchCode} - ${payment.branchName}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4">
                <span class="text-bold">Banco: </span>
                <span>{{ `${payment.bank}` }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4">
                <span class="text-bold">Conta: </span>
                <span>{{ `${payment.account || ""}` }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4">
                <span class="text-bold">Agência: </span>
                <span>{{
                  `${payment.agency || ""} - ${payment.bankName}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4">
                <span class="text-bold">Data da Solic.: </span>
                <span>{{
                  `${formatString(payment.dtmovi || "", "date")}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4">
                <span class="text-bold">Centro de Custo: </span>
                <span>{{ payment.costCenter }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4">
                <span class="text-bold">Valor Pagamento: </span>
                <span>{{ formatString(payment.value || "", "currency") }}</span>
              </div>
              <div class="col-12 q-mt-sm">
                <span class="text-bold">Justificativa: </span>
                <span>{{ payment.historic }}</span>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div v-else>
        <productsCard :data="payment" />
      </div>
    </q-scroll-area>

    <div class="row justify-evenly q-mb-xl">
      <div class="col-auto">
        <q-btn color="green" icon="check" label="Aprovar" @click="approve" />
      </div>
      <div class="col-auto">
        <q-btn color="red" icon="block" label="Rejeitar" @click="reject" />
      </div>
    </div>
    <ApprovalHierarchy ref="ApprovalHierarchy" />

    <div class="RejectionReason">
      <q-dialog v-model="modalReason" persistent square full-width>
        <q-card class="bg-white">
          <q-card-section>
            <div class="text-h6">Rejeitar Pagamento</div>
            <div class="text-subtitle2">
              Favor informar o motivo da rejeição:
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="descriptionRejection"
              type="textarea"
              label="Descrição do Motivo"
              outlined
            />
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly q-mb-xl">
              <div class="col-auto">
                <q-btn
                  color="grey-8"
                  label="Cancelar"
                  @click="modalReason = false"
                />
              </div>
              <div class="col-auto">
                <q-btn color="red" label="Rejeitar" @click="confirmReject" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
// import DataTable from "@/components/DataTable";
import productsCard from "./productsCard";
import ApprovalHierarchy from "./ApprovalHierarchy";
import formatString from "@/helpers/formatString";

export default {
  components: {
    // DataTable,
    productsCard,
    ApprovalHierarchy
  },
  data() {
    return {
      modalReason: false,
      descriptionRejection: null,
      payment: null,
      viewTable: true,
      columns: [
        {
          field: "item",
          label: "Item",
          align: "center",
          sortable: true
        },
        {
          field: "productDescription",
          label: "Produto",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "um",
          label: "UM",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "quantity",
          label: "Qt.",
          align: "right",
          type: "number",
          sortable: true
        },
        {
          field: "unitaryValue",
          label: "Vlr.Unit.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "unitaryDiscount",
          label: "Desc.Unit.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "value",
          label: "Valor",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "ipi",
          label: "IPI",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "valueLastPurchase",
          label: "Valor Últ. Entrada",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "deliveryDate",
          label: "Dt. Entrega",
          align: "center",
          type: "date",
          sortable: true
        }
      ]
    };
  },
  computed: {
    paymentId() {
      return this.$route.params.paymentId;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("payments", ["getPaymentsById", "approvesRejectsPayments"]),
    ...mapMutations("shared", ["setNotification"]),
    formatString,
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.payment.approvalHierarchy);
    },
    approve() {
      this.sendResponse(this.payment, "approved", "APROVAR", "text-green");
    },
    reject() {
      this.modalReason = true;
    },
    sendResponse(payment, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Lançamento ${payment.documentNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            const reasonReject =
              response === "rejected" ? this.descriptionRejection : "approved";
            await this.approvesRejectsPayments({
              payment,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.$router.push({ name: "payments" });
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    confirmReject() {
      if (
        String(this.descriptionRejection).length < 8 ||
        this.descriptionRejection === null ||
        this.descriptionRejection === ""
      ) {
        this.$q.dialog({
          title: "Cicopal Aprova",
          message: "Favor detalhar o motivo da Rejeição deste pagamento!"
        });

        return;
      }

      this.sendResponse(this.payment, "rejected", "REJEITAR", "text-red");
    }
  },
  async created() {
    try {
      this.payment = await this.getPaymentsById(this.paymentId);
      console.log(this.payment);
    } catch (error) {
      console.log(error.message);
      this.$router.back();
    }
  }
};
</script>
<style lang="scss">
.PaymentDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
